@keyframes changeBorderColorAndShadow {
  0% { 
      border-color: #c95133; 
      box-shadow: 0px 0px 5px #c95133;
  }
  25% { 
      border-color: #049333; /* Red */
      box-shadow: 0px 0px 3px #049333; /* Red */
  }
  50% { 
      border-color: #20497f; /* Green */
      box-shadow: 0px 0px 5px #20497f; /* Green */
  }
  75% { 
      border-color: #e9c46a; /* Blue */
      box-shadow: 0px 0px 3px #e9c46a; /* Blue */
  }
  100% { 
      border-color: #c95133; /* Original Color */
      box-shadow: 0px 0px 5px #c95133; /* Original Color */
  }
}

.color-changing-border {
  animation: changeBorderColorAndShadow 4s infinite ease-in-out;
  box-shadow: 0px 0px 3px; /* Initialize shadow */
}
