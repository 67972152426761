.ql-snow.ql-toolbar {
  border: none;
}

.ql-toolbar.ql-snow {
  border: none !important;
  font-family: Montserrat, sans-serif;
  border-radius: 10px 10px 0px 0px;
  background-color: rgb(242, 244, 249);
  /* border: 1px dotted black; */
  /* box-shadow: 0px 0px 7px rgba(5, 113, 245, 0.4); */
  -webkit-text-fill-color: black !important;
}

.ql-picker-label {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
}

.ql-editor {
  border: none;
  font-size: medium;
  font-family: Montserrat, sans-serif;
}
.ql-editor {
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 0px 7px rgba(33, 73, 127, 0.4);
  padding: 1px;
}

.netpad-boxShadow-top {
  box-shadow: 0px 0px 7px rgba(33, 73, 127, 0.4);
}

.ql-container.ql-snow {
  border: 0px !important;
}

.ql-syntax {
  background-color: rgb(0, 4, 14) !important;
  /* color: rgb(88, 245, 96) !important; */
  /* border-radius: 5px !important; */
}

/* .animateQuill {
  animation-name: shadowAnimation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
} */

@keyframes shadowAnimation {
  0% {
    box-shadow: 0 0 0 rgba(31, 82, 148, 0.4);
  }
  50% {
    box-shadow: 0 0 14px rgba(33, 73, 127, 0.4); /* Expand the shadow */
  }
  100% {
    box-shadow: 0 0 0 rgba(33, 73, 127, 0.4); /* Reduce the shadow back to 0 */
  }
}

.todo-item {
  display: flex;
  align-items: center;
}

.todo-item input[type="checkbox"] {
  margin-right: 0.5em;
}
